import React, { Component } from "react";
import PropTypes from "prop-types";

export class EmbeddingPanel extends Component {
  render() {
    const { page } = this.props;
    const { slug } = page;
    return (
      <div>
        <p>
          To embed this upload form in your own website, use the following HTML
          code:
        </p>
        <pre>
          &lt;a href="https://fileinbox.com/{slug}" class="fileinbox"&gt;Upload
          Files through fileinbox.com&lt;/a&gt; &lt;script
          type="text/javascript"
          src="https://fileinbox.com/embed.js"&gt;&lt;/script&gt;
        </pre>
      </div>
    );
  }
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
EmbeddingPanel.propTypes = { page: PropTypes.object };

export default EmbeddingPanel;
