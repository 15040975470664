import { useState, useEffect } from "react";
import axios from "axios";
// import originalSlugify from "slugify";

import useDebounce from "./useDebounce"

// export const slugify = str => {
//   str = str.replace(/\s$/, "-").replace(/[^A-Za-z0-9_-]/g, "");
//   return originalSlugify(str);
// };

export const slugify = str => {
  str = str.replace(/\s+/g, "-").replace(/[^A-Za-z0-9\_\-]/g, "");
  // return originalSlugify(str);
  return str;
};

export default defaultSlug => {
  const [slug, setSlug] = useState(defaultSlug);
  const [slugLoading, setLoading] = useState(true);
  const [slugAvailable, setAvailable] = useState(null);
  const [slugMessage, setMessage] = useState("");
  const [slugValid, setValid] = useState(false);

  // overwrite state with props
  useEffect(() => setSlug(defaultSlug), [defaultSlug]);

  // anytime the slug changes, we need to reload
  useEffect(() => {
    setLoading(true);
    setAvailable(null);
  }, [slug]);

  useEffect(() => {
    if (slug.length === 0) {
      setMessage("");
    } else if (slug.length <= 2) {
      setMessage("🔬 your web address needs to be a little longer");
    } else if (slugLoading) {
      setMessage(`🧐 checking fileinbox.com/${slug}...`);
    } else if (slugAvailable) {
      setMessage(`✅ fileinbox.com/${slug} is available! 🥳`);
    } else {
      setMessage(`❌ fileinbox.com/${slug} is taken 🙁`);
    }

    setValid(slug.length > 2 && slugAvailable && !slugLoading);
  }, [slug, slugLoading, slugAvailable]);

  useDebounce(
    () => {
      if (!slug || slug.length <= 2) return;

      axios
        .get(`/pages/check_slug?slug=${encodeURIComponent(slug)}`)
        .then(({ data: { available, slug: checkedSlug } }) => {
          if (slug === checkedSlug) {
            setLoading(false);
            setAvailable(available);
          }
        });
    },
    1000,
    [slug]
  );

  return { slug, setSlug, slugLoading, slugAvailable, slugValid, slugMessage };
};
