import React, { Component } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-spring";

export class SaveButtons extends Component {
  render() {
    const { discardChanges, save, saving, show } = this.props;
    return (
      <div
        style={{
          position: "fixed",
          // bottom: show ? 0 : -50,
          bottom: 0,
          left: 10,
          right: 10,
          zIndex: 10,
          display: show ? "flex" : "none",
        }}
      >
        <button
          disabled={saving}
          type="button"
          onClick={(e) => {
            e.preventDefault();
            discardChanges();
          }}
          className="btn btn-default btn-lg"
        >
          discard changes
        </button>
        <button
          disabled={saving}
          style={{ flex: 1 }}
          className="btn btn-success btn-lg"
          onClick={(e) => {
            e.preventDefault();
            save();
          }}
        >
          {saving ? "saving..." : "save"}
        </button>
      </div>
    );
    // return (
    //   <Transition
    //     from={{ bottom: -80 }}
    //     enter={{ bottom: 10 }}
    //     leave={{ bottom: -50 }}
    //     config={{ tension: 300, friction: 7 }}
    //   >
    //     {show &&
    //       ((styles) => (
    //         <div
    //           style={{
    //             position: "fixed",
    //             // bottom: show ? 0 : -50,
    //             bottom: 0,
    //             left: 10,
    //             right: 10,
    //             zIndex: 10,
    //             display: "flex",
    //             ...styles,
    //           }}
    //         >
    //           <button
    //             disabled={saving}
    //             type="button"
    //             onClick={(e) => {
    //               e.preventDefault();
    //               discardChanges();
    //             }}
    //             className="btn btn-default btn-lg"
    //           >
    //             discard changes
    //           </button>
    //           <button
    //             disabled={saving}
    //             style={{ flex: 1 }}
    //             className="btn btn-success btn-lg"
    //             onClick={(e) => {
    //               e.preventDefault();
    //               save();
    //             }}
    //           >
    //             {saving ? "saving..." : "save"}
    //           </button>
    //         </div>
    //       ))}
    //   </Transition>
    // );
  }
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
SaveButtons.propTypes = {};

export default SaveButtons;
