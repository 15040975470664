import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
// import VisibilitySensor from "react-visibility-sensor";

let data = [
  { count: 4785076, size: 11689529694257, label: "Photos" },
  { count: 95335, size: 7603825763546, label: "Videos" },
  { count: 73009, size: 1392442516799, label: "Audio files" },
  { count: 246212, size: 108267995620, label: "Documents" },
  { count: 44748, size: 375342089253, label: "Powerpoints" },
  { count: 100211, size: 76357384314, label: "Excel sheets" },
  { count: 429934, size: 1431156407739, label: "PDFs" },
];

data = data.sort((a, b) => b.count - a.count);
data.push({ count: 655447, size: 19536241884656, label: "Other things" });

const icons = {
  Photos: "fa-file-image-o",
  Videos: "fa-file-video-o",
  "Audio files": "fa-file-audio-o",
  Documents: "fa-file-word-o",
  Powerpoints: "fa-file-powerpoint-o",
  "Excel sheets": "fa-file-excel-o",
  PDFs: "fa-file-pdf-o",
  "Other things": "fa-file-o",
};

const humanFileSize = bytes => {
  const si = true;
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  var units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
};

export default () => {
  return (
    <div>
      <ul style={{ fontSize: "20pt", listStyle: "none", paddingLeft: 0 }}>
        {data.map(({ count, size, label }) => (
          <li key={label}>
            <i
              className={`fa ${icons[label]}`}
              style={{ marginRight: "0.5em", color: "#4cade9" }}
            />
            <TrackVisibility once style={{ display: "inline" }}>
              {({ isVisible }) =>
                isVisible ? (
                  <CountUp start={0} end={count} duration={3} separator="," />
                ) : (
                  count.toLocaleString("en")
                )
              }
            </TrackVisibility>{" "}
            <b>{label}</b> ({humanFileSize(size)})
          </li>
        ))}
      </ul>
    </div>
  );
};
