import React, { Component } from "react";
import PropTypes from "prop-types";
import { TrixEditor } from "react-trix";
import Textarea from "react-textarea-autosize";

export class WhitelabelPanel extends Component {
  render() {
    const { page, user, onChange } = this.props;

    const {
      title,
      upload_instructions,
      hide_layout,
      hide_powered_by,
      html,
      redirect_to_app,
    } = page;

    const { active_plan } = user;
    const canEditTitle = !!active_plan.edit;
    const canAddUploadInstructions = !!active_plan.add_upload_instructions;
    const canHideDescription = !!active_plan.hide_layout;
    const canHidePoweredBy = !!active_plan.hide_powered_by;

    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="page_title">
            Title
          </label>
          <input
            value={title || ""}
            placeholder="Send files to Christian Genco"
            className="form-control"
            type="text"
            // name="page[title]"
            disabled={!canEditTitle}
            id="page_title"
            onChange={(e) => onChange({ title: e.target.value })}
          />
          {canEditTitle || (
            <span className="help-block">
              <a href="/account/billing">Upgrade</a> to edit this page's title.
            </span>
          )}
        </div>

        <br />

        <div className="form-group">
          <label className="control-label" htmlFor="page_upload_instructions">
            Upload Instructions
          </label>

          {canAddUploadInstructions ? (
            <TrixEditor
              onChange={(upload_instructions, text) =>
                onChange({ upload_instructions })
              }
              onEditorReady={(editor) => editor.insertHTML(upload_instructions)}
            />
          ) : (
            <textarea
              className="form-control"
              style={{ display: "block", width: "100%" }}
              disabled={true}
              value={""}
              //onChange={e => onChange({ upload_instructions: e.target.value })}
            />
          )}

          {canAddUploadInstructions || (
            <span className="help-block">
              <a href="/account/billing">Upgrade</a> to add instructions for
              your uploaders.
            </span>
          )}
        </div>

        <div className="form-group">
          <div className="checkbox">
            <label htmlFor="page_hide_powered_by">
              <input name="page[hide_powered_by]" type="hidden" value="0" />
              <input
                type="checkbox"
                checked={hide_powered_by}
                onChange={(e) =>
                  onChange({ hide_powered_by: e.target.checked })
                }
                // name="page[hide_powered_by]"
                id="page_hide_powered_by"
                disabled={!canHidePoweredBy}
              />
              <b>Hide "powered by Fileinbox"</b>{" "}
              {canHidePoweredBy || (
                <small>
                  <a href="/account/billing">Upgrade</a> to hide "powered by
                  Fileinbox" on your upload form.
                </small>
              )}
            </label>
          </div>

          <div className="checkbox">
            <label htmlFor="page_hide_layout">
              <input name="page[hide_layout]" type="hidden" value="0" />
              <input
                type="checkbox"
                checked={hide_layout}
                onChange={(e) => onChange({ hide_layout: e.target.checked })}
                // name="page[hide_layout]"
                id="page_hide_layout"
                disabled={!canHideDescription}
              />
              <b>Hide description of Fileinbox</b>{" "}
              {canHideDescription || (
                <small>
                  <a href="/account/billing">Upgrade</a> to hide the description
                  of the Fileinbox service.
                </small>
              )}
            </label>
          </div>
        </div>

        {user.email === "christian.genco@gmail.com" && (
          <div>
            <Textarea
              value={html || ""}
              className="form-control"
              style={{
                display: "block",
                width: "100%",
                color: "white",
                backgroundColor: "black",
                fontFamily: "Triplicate T4c",
                fontSize: "14pt",
              }}
              onChange={(e) => onChange({ html: e.target.value })}
            />
            <div className="checkbox">
              <label htmlFor="page_redirect_to_app">
                <input name="page[redirect_to_app]" type="hidden" value="0" />
                <input
                  type="checkbox"
                  checked={redirect_to_app}
                  onChange={(e) =>
                    onChange({ redirect_to_app: e.target.checked })
                  }
                  // name="page[redirect_to_app]"
                  id="page_redirect_to_app"
                />
                <b>Redirect to fileinbox.app</b>{" "}
              </label>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
WhitelabelPanel.propTypes = {
  page: PropTypes.object,
  onChange: PropTypes.func,
};

export default WhitelabelPanel;
