import React, { useEffect, useState } from "react";
import Textarea from "react-textarea-autosize";
import axios from "axios";

// const onSave = fn => {
//   document.addEventListener(
//     "keydown",
//     e => {
//       if (
//         e.keyCode == 83 &&
//         (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
//       ) {
//         e.preventDefault();
//         fn();
//       }
//     },
//     false
//   );
// };

export const Note = props => {
  const [note, setNote] = useState(props.note);
  const [body, setBody] = useState(props.note.body || "");

  const [submitting, setSubmitting] = useState(false);
  const [res, setRes] = useState({});
  const [error, setError] = useState(null);
  const onSubmit = e => {
    e && e.preventDefault();
    setSubmitting(true);

    // console.log({ body });

    axios
      .post(`/users/${props.note.user_id}/upsert_note.json`, {
        note: { body },
      })
      .then(res => {
        setSubmitting(false);
        setRes(res);
        setNote(res.data);
        // console.log({ res });
      })
      .catch(err => {
        setSubmitting(false);
        setError(err);
        window.notice({
          title: "Error",
          body: err,
        });
      });
  };

  // this is dumb because it recreates this function with every re-render but
  // I CAN'T FIGURE OUT HOW TO NOT DO THAT
  useEffect(() => {
    const fn = e => {
      if (
        e.keyCode == 83 &&
        (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
      ) {
        e.preventDefault();
        // console.log({ area: "in addEventListener", note, body });
        onSubmit();
      }
    };
    document.addEventListener("keydown", fn, false);
    return () => document.removeEventListener("keydown", fn);
  }, [body]);

  return (
    <form
      action={`/users/${props.note.user_id}/upsert_note`}
      method="POST"
      onSubmit={onSubmit}
    >
      <Textarea
        className="form-control"
        name="note[body]"
        value={body}
        onChange={e => setBody(e.target.value)}
      />
      <button
        type="submit"
        className="btn btn-primary btn-block btn-lg"
        disabled={note && body === note.body}
      >
        {submitting ? "Saving..." : "Save"}
      </button>
    </form>
  );
};

export default Note;
