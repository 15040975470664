import React, { useEffect } from "react";

export default () => {
  useEffect(() => {
    document.getElementById("footer").style.display = "none";
    document.getElementById("navigation").style.display = "none";

    return () => {
      document.getElementById("footer").style.display = "block";
      document.getElementById("navigation").style.display = "block";
    };
  });
  return React.Fragment;
};
