import React, { Component } from "react";
import PropTypes from "prop-types";

import { ChromePicker } from "react-color";

class ColorPicker extends Component {
  state = { expanded: false };

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target)) return;
    this.setState({ expanded: false });
  };

  render() {
    const { value, onChange, disabled } = this.props;
    const { expanded } = this.state;

    return (
      <div
        // className="input-group-addon"
        ref={node => (this.node = node)}
        style={{
          // display: "inline-block",
          minWidth: 30,
          minHeight: 50,
          border: "1px dashed #888",
          borderRadius: 10,
          cursor: disabled ? "not-allowed" : "pointer",
          backgroundColor: value,
        }}
        onClick={() => disabled || this.setState({ expanded: true })}
      >
        {expanded && (
          <div style={{ position: "absolute", zIndex: 10 }}>
            <ChromePicker color={value} onChange={({ hex }) => onChange(hex)} />
          </div>
        )}
      </div>
    );
  }
}

// const ColorInput = ({ value, onChange }) => {
//   return (
//     <div className="input-group">
//       <input
//         placeholder="default"
//         className="form-control"
//         type="text"
//         value={value}
//         onChange={onChange}
//         // name="page[css][primary_background]"
//         // id="page_css_primary_background"
//       />
//       <ColorPicker value={value} onChange={onChange} />
//       {/* <span className="input-group-addon">
//       </span> */}
//     </div>
//   );
// };

export class ThemePanel extends Component {
  render() {
    const { onChange, user, page } = this.props;
    const { css } = page;

    const { active_plan } = user;
    const canEditTheme = !!active_plan.theme;

    const colors = {
      primary_background: {
        label: "Primary background",
        defaultValue: "#f5f5f5",
      },
      primary_color: { label: "Primary text color", defaultValue: "#333333" },
      dropzone_background: {
        label: "Dropzone background color",
        defaultValue: "#41a6c4",
      },
      dropzone_color: { label: "Dropzone text color", defaultValue: "#FFFFFF" },
      success_color: { label: "Success color", defaultValue: "#42D66F" },
    };

    return (
      <div>
        {Object.entries(colors).map(([key, { label, defaultValue }]) => (
          <div key={key} className="form-group">
            <label
              className="control-label"
              htmlFor="page_css_primary_background"
            >
              {label}
            </label>
            <ColorPicker
              value={css[key] || defaultValue}
              onChange={val => onChange({ css: { ...css, [key]: val } })}
              disabled={!canEditTheme}
            />
          </div>
        ))}
        {canEditTheme || (
          <span>
            <a href="/account/billing">Upgrade</a> to edit this page's theme to
            match your website.
          </span>
        )}
      </div>
    );
  }
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
ThemePanel.propTypes = {
  page: PropTypes.object,
  onChange: PropTypes.func,
};

export default ThemePanel;
