import React from "react";
import PropTypes from "prop-types";
import { HashRouter, Route, Redirect } from "react-router-dom";
import axios from "axios";
import isEqual from "lodash.isequal";
import pick from "lodash.pick";

import PageSettingsMenu from "./page_settings/PageSettingsMenu";
import SaveButtons from "./page_settings/SaveButtons";

import EmbeddingPanel from "./page_settings/EmbeddingPanel";
import NotificationsPanel from "./page_settings/NotificationsPanel";
import SecurityPanel from "./page_settings/SecurityPanel";
import ThemePanel from "./page_settings/ThemePanel";
import UploadingPanel from "./page_settings/UploadingPanel";
import WhitelabelPanel from "./page_settings/WhitelabelPanel";

class PageSettings extends React.Component {
  state = {
    page: this.props.page,
    serverPage: this.props.page,
    saving: false,
    dirty: false,
  };

  onChange = (params) => {
    // console.info(params);
    const page = { ...this.state.page, ...params };
    // probably expensive but TOTALLY WORTH IT
    const dirty = !isEqual(page, this.state.serverPage);
    this.setState({ page, dirty });
  };

  discardChanges = () => {
    this.setState({ page: this.state.serverPage, dirty: false, saving: false });
  };

  save = () => {
    const { page } = this.state;
    const { authenticity_token } = this.props;

    const pageData = pick(
      page,
      "slug",
      "title",
      "access_code",
      "enable_submission_folders",
      "subfolder_name",
      "email_frequency",
      "folder_path",
      "file_path",
      "cc_emails",
      "upload_instructions",
      "hide_layout",
      "hide_powered_by",
      "question_response_format",
      "questions",
      "css",
      "cloud_destination",
      "html",
      "redirect_to_app"
    );

    this.setState({ saving: true });

    axios.defaults.headers.common["X_CSRF_TOKEN"] = authenticity_token;

    axios
      .patch(`/pages/${page.id}.json`, {
        page: pageData,
      })
      .then(({ data }) => {
        this.setState({ saving: false, dirty: false, serverPage: data });
      })
      .catch((err) => {
        let body = Object.values(err.response.data || {}).join(", ");
        body || (body = err.message);

        // TODO: replace notice with a more react solution
        window.notice({
          body,
          title: "Error saving page settings",
        });
        this.setState({ saving: false });
      });
  };

  render() {
    const { user } = this.props;
    const { page, saving, dirty } = this.state;

    return (
      <form>
        <HashRouter>
          <div className="row" style={{ minHeight: "75vh" }}>
            <Route
              path="/"
              exact
              render={() => <Redirect to="/whitelabel" />}
            />

            <div className="col-sm-3">
              <PageSettingsMenu />
            </div>
            <div className="col-sm-9">
              <Route
                path="/whitelabel"
                render={() => (
                  <WhitelabelPanel
                    page={page}
                    user={user}
                    onChange={this.onChange}
                  />
                )}
              />
              <Route
                path="/theme"
                render={() => (
                  <ThemePanel
                    page={page}
                    user={user}
                    onChange={this.onChange}
                  />
                )}
              />
              <Route
                path="/security"
                render={() => (
                  <SecurityPanel
                    page={page}
                    user={user}
                    onChange={this.onChange}
                  />
                )}
              />
              <Route
                path="/uploading"
                render={() => (
                  <UploadingPanel
                    page={page}
                    user={user}
                    onChange={this.onChange}
                  />
                )}
              />
              <Route
                path="/notifications"
                render={() => (
                  <NotificationsPanel
                    page={page}
                    user={user}
                    onChange={this.onChange}
                  />
                )}
              />
              <Route
                path="/embedding"
                render={() => <EmbeddingPanel user={user} page={page} />}
              />
            </div>
            <SaveButtons
              discardChanges={this.discardChanges}
              save={this.save}
              saving={saving}
              show={dirty}
            />
          </div>
        </HashRouter>
      </form>
    );
  }
}

PageSettings.propTypes = {
  page: PropTypes.object,
  user: PropTypes.object,
};
export default PageSettings;
