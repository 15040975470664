import React from "react";
// import PropTypes from 'prop-types';

const icons = {
  archive: [
    "zip",
    "rar",
    "sitx",
    "sit",
    "dmg",
    "iso",
    "7z",
    "gz",
    "tar",
    "cab",
    "sea",
    "tgz",
  ],
  audio: [
    "mp3",
    "avi",
    "wav",
    "wma",
    "cdr",
    "aif",
    "aiff",
    "m4a",
    "rm",
    "m4p",
    "ogg",
    "ram",
  ],
  code: [
    "exe",
    "dwg",
    "py",
    "rb",
    "pl",
    "c",
    "cpp",
    "mdb",
    "chm",
    "jar",
    "java",
    "htm",
    "html",
    "css",
    "js",
    "coffee",
    "erb",
    "slim",
    "eco",
    "log",
    "sql",
  ],
  excel: ["xls", "xlsx", "qbw"],
  image: [
    "jpg",
    "jpeg",
    "tif",
    "tiff",
    "psd",
    "bmp",
    "png",
    "gif",
    "ico",
    "cbr",
    "cr2",
  ],
  video: [
    "wmv",
    "mpg",
    "avi",
    "mov",
    "mp4",
    "mpeg",
    "swf",
    "flv",
    "divx",
    "3gp",
  ],
  pdf: ["pdf", "eps", "ai", "indd", "ps"],
  powerpoint: ["ppt", "pptx", "pps"],
  text: ["txt", "md", "markdown", "mdown", "rtf", "wps"],
  word: ["doc", "docx", "pub"],
};

const extensions = {};
Object.entries(icons).forEach(([key, values]) =>
  values.forEach(value => (extensions[value] = key))
);

// @name.match(/\.(\w{2,4})$/)?[1]?.toLowerCase()
const extname = filename =>
  filename
    .split(".")
    .slice(1)
    .pop() || "";

export const FileIcon = ({ filename, isDirectory }) => {
  if (isDirectory) return <i className={`fa fa-folder-o`} />;
  let icon = "fa-file-o";
  const ext = extname(filename);
  if (extensions[ext]) icon = `fa-file-${extensions[ext]}-o`;

  return <i className={`fa ${icon}`} />;
};

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
// FileIcon.propTypes = {

// };

export default FileIcon;
