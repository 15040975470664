import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";

import { getFirestore, doc, getDoc, onSnapshot } from "firebase/firestore";

import MessageForm from "./MessageForm";
import Livestream from "./Livestream";

const firebaseConfig = {
  apiKey: "AIzaSyC8j_erqSBNi2cFomSoMRDwpzY2-87ghhA",
  authDomain: "fileinbox-4e544.firebaseapp.com",
  databaseURL: "https://fileinbox-4e544.firebaseio.com",
  projectId: "fileinbox-4e544",
  storageBucket: "fileinbox-4e544.appspot.com",
  messagingSenderId: "97941959110",
  appId: "1:97941959110:web:f22371418073270dbf42c7",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default function HelpWidget({ user, authenticity_token }) {
  const [live, setLive] = useState(false);
  const [hideLivestream, setHideLivestream] = useState(false);

  useEffect(() => {
    const docRef = doc(db, "config", "christian");
    // const docSnap = await getDoc(docRef);
    // if (docSnap.exists()) {
    //   console.log(docSnap.data());
    // }
    return onSnapshot(docRef, (doc) => {
      const { online } = doc?.data() || { online: false };
      setLive(!!online);
    });
  }, []);
  return live && !hideLivestream ? (
    <Livestream setHide={setHideLivestream} />
  ) : (
    <MessageForm user={user} authenticity_token={authenticity_token} />
  );
}
