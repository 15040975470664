import React from "react";
// import PropTypes from 'prop-types';

import { NavLink, withRouter } from "react-router-dom";
import Icon from "../FaIcon";

const NavItem = withRouter(
  ({ to, label, iconName, location: { pathname } }) => {
    return (
      <li role="presentation" className={to === pathname ? "active" : ""}>
        <NavLink to={to}>
          <Icon name={iconName} /> {label}
        </NavLink>
      </li>
    );
  }
);

export const PageSettingsMenu = props => {
  return (
    <ul className="nav nav-pills nav-stacked">
      <NavItem to="/whitelabel" label="Whitelabel" iconName="tag" />

      <NavItem to="/theme" label="Theme" iconName="paint-brush" />

      <NavItem to="/security" label="Security" iconName="lock" />

      <NavItem to="/uploading" label="Uploading" iconName="cloud-upload" />

      <NavItem to="/notifications" label="Notifications" iconName="bell" />

      <NavItem to="/embedding" label="Embedding" iconName="code" />
    </ul>
  );
};

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
// PageSettingsMenu.propTypes = {
// };

export default PageSettingsMenu;
