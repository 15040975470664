import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

export class Page extends Component {
  state = { dragging: false };

  render() {
    const { dragging } = this.state;
    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <button className="btn btn-primary btn-sm pull-right">
            <i className="fa fa-plus" /> Choose Files
          </button>
          <div className="clearfix" />
        </div>
        <div
          className="panel-body"
          style={{
            backgroundColor: dragging ? "#42d66f" : "#41a6c4",
            textAlign: "center",
            color: "white",
            border: "5px dotted white",
            padding: "50px",
          }}
        >
          <h1 style={{ margin: 0 }}>
            {dragging ? (
              <Fragment>
                <i className="fa fa-arrow-circle-down" /> Drop files here to
                upload.
              </Fragment>
            ) : (
              <Fragment>
                <i className="fa fa-arrow-circle-up" /> Drag files here to
                upload.
              </Fragment>
            )}
          </h1>
        </div>
        <div className="panel-footer">
          <p style={{ margin: 0 }}>
            Powered by{" "}
            <a
              target="_blank"
              href="https://dbinbox.com/?utm_source=dbi&amp;utm_medium=embed&amp;utm_campaign=powered_by"
              style={{ color: "black" }}
            >
              <img
                style={{
                  height: "1em",
                  display: "inline",
                }}
                src="/assets/dbinbox_logo-018a3e20d4e6fd241c6231c298e0ec7bec07505ded3b56654ad0b4bdf37a5ef8.png"
                alt="Dbinbox logo"
              />dbinbox.com
            </a>
          </p>
        </div>
      </div>
    );
  }
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
Page.propTypes = {};

export default Page;
