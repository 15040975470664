import React, { Component } from "react";
import PropTypes from "prop-types";

export class NotificationsPanel extends Component {
  render() {
    const { page, user, onChange } = this.props;
    const { email_frequency, cc_emails } = page;

    const inputs = [["As files are uploaded", 0], ["Never", -1]];

    const { active_plan } = user;
    const canAddCCEmails = !!active_plan.add_cc_emails;

    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="page_email_frequency">
            Send email notifications:
          </label>
          <div style={{ marginLeft: "20px" }}>
            {inputs.map(([text, value], i) => (
              <div className="radio" key={i}>
                <label htmlFor={`page_email_frequency_${i}`}>
                  <input
                    type="radio"
                    value={value}
                    checked={value === email_frequency}
                    // name="page[email_frequency]"
                    id={`page_email_frequency_${i}`}
                    onChange={e =>
                      onChange({ email_frequency: parseInt(e.target.value) })
                    }
                  />
                  {text}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="page_cc_emails">
            CC emails
          </label>
          <input
            placeholder="christian@dbinbox.com, kate@dbinbox.com"
            className="form-control"
            type="text"
            value={cc_emails}
            // name="page[cc_emails]"
            onChange={e => onChange({ cc_emails: e.target.value })}
            id="page_cc_emails"
            disabled={!canAddCCEmails}
          />
          {canAddCCEmails ? (
            <span className="help-block">
              send a copy of file upload notifications to these email addresses
              (seperate with commas)
            </span>
          ) : (
            <span className="help-block">
              <a href="/account/billing">Upgrade</a> to send a copy of file
              upload notifications to other email addresses.
            </span>
          )}
        </div>
      </div>
    );
  }
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
NotificationsPanel.propTypes = {
  page: PropTypes.object,
  onChange: PropTypes.func,
};

export default NotificationsPanel;
