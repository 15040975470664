import React, { useState, useEffect } from "react";
import useSlug, { slugify } from "./hooks/useSlug";

export default ({ authenticity_token, user }) => {
  const [name, setName] = useState("");
  const {
    slug,
    setSlug,
    slugLoading,
    slugAvailable,
    slugMessage,
    slugValid,
  } = useSlug("");

  useEffect(() => {
    if (true) {
      setSlug(slugify(name));
    }
  }, [name]);

  return (
    <div style={{ fontSize: "15pt", minHeight: "90vh" }}>
      <h1>Create {name ? `${name}'s` : "a"} File Upload Page</h1>
      <form className="well" action="/pages" method="post" charset="UTF-8">
        <input name="utf8" type="hidden" value="✓" />
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticity_token}
        />
        <input
          type="hidden"
          name="page[title]"
          value={`Send files to ${name}`}
        />

        <div className="form-group">
          <label htmlFor="name">
            What's the name of the Business or Project that needs to receive
            files?
          </label>
          <input
            id="name"
            type="text"
            className="form-control input-lg"
            placeholder={`ex: "Acme Accounting"`}
            value={name}
            onChange={e => setName(e.target.value)}
            autoComplete="off"
          />
          <span className="help-block">
            This helps Fileinbox figure out the web address and default title of
            your upload page.
          </span>
        </div>
        <div className="form-group">
          <label htmlFor="name">
            What would you like the page's web address to be?
          </label>
          <div className="input-group">
            <div className="input-group-addon">fileinbox.com/</div>
            <input
              id="name"
              name="page[slug]"
              type="text"
              className="form-control input-lg"
              placeholder={`ex: "acme-accounting"`}
              value={slug}
              onChange={e => setSlug(slugify(e.target.value))}
              autoComplete="off"
            />
          </div>
          {slugMessage && <span className="help-block">{slugMessage}</span>}
        </div>
        <button
          disabled={!slugValid}
          type="submit"
          className="btn btn-primary btn-lg btn-block"
        >
          Create Upload Page
        </button>
      </form>
    </div>
  );
};
