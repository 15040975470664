import React, { useState } from "react";
export default function Livestream({ user, setHide }) {
  return (
    <div
      style={{
        position: "fixed",
        boxSizing: "border-box",
        right: 35,
        bottom: 0,
        width: 180,
        // height: (180 * 9) / 16 + 80,
        background: "#fff",
        color: "#444",
        fontSize: 13,
        borderRadius: "10px 10px 0 0",
        boxShadow: "0px 10px 20px rgba(0,0,0,0.3)",
        zIndex: 999999,
        transform: `translateY(0px) translateZ(10px)`,
        transition: "0.3s transform",
      }}
    >
      <button
        onClick={() => setHide(true)}
        type="button"
        className="btn btn-danger btn-sm"
        style={{
          position: "absolute",
          top: -3,
          right: -3,
          zIndex: 9999990,
          borderRadius: "100px",
        }}
      >
        &times;
      </button>
      <video
        autoPlay
        loop
        muted
        style={{ width: 180, borderRadius: "10px 10px 0 0" }}
        src="https://res.cloudinary.com/genco/video/upload/v1657818350/video/livestream.mp4"
      ></video>
      <div style={{ padding: 5 }}>
        Questions? Video chat with the founder live, right now. (Really!)
        <a
          target="_blank"
          href="https://whereby.com/fileinbox"
          className="btn btn-sm btn-primary"
          style={{ display: "block", marginTop: "5px" }}
        >
          Open Video Chat
        </a>
      </div>
    </div>
  );
}

// add CTA button
// add close button
