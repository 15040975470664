import React, { useState, useEffect } from "react";

import NextPrevButtons from "./NextPrevButtons";

import useSlug, { slugify } from "../hooks/useSlug";

export default ({ value, setValue, name, setName, next, prev }) => {
  const {
    slug,
    setSlug,
    slugLoading,
    slugAvailable,
    slugMessage,
    slugValid,
  } = useSlug(value);

  useEffect(() => setSlug(slugify(value)), [value]);

  useEffect(() => {
    if (name) {
      setSlug(slugify(name));
    }
  }, [name]);

  return (
    <div className="well">
      <form
        onSubmit={e => {
          e.preventDefault();
          if (slugValid) {
            setValue(slug);
            next();
          }
        }}
      >
        <div className="form-group">
          <label htmlFor="name">
            First, what's the name of the Business or Project that needs to
            receive files?
          </label>
          <input
            id="name"
            type="text"
            className="form-control input-lg"
            autoComplete="off"
            placeholder={`Your business or project name`}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <span className="help-block">
            This helps Fileinbox figure out the web address and default title of
            your upload page.
          </span>
        </div>

        <div className="form-group">
          <label htmlFor="name">How does this web address look?</label>
          <div className="input-group">
            <div className="input-group-addon">fileinbox.com/</div>
            <input
              id="name"
              type="text"
              className="form-control input-lg"
              placeholder={`ex: "Acme Accounting"`}
              value={value}
              onChange={e => setValue(slugify(e.target.value))}
            />
            {/*<div className="input-group-addon">{"✅" || "❌"}</div>*/}
          </div>
          {slugMessage && <span className="help-block">{slugMessage}</span>}
        </div>

        <NextPrevButtons
          next={next}
          prev={prev}
          nextText={!slugValid ? "Next" : "Next"}
          nextDisabled={!slugValid}
        />
      </form>
    </div>
  );
};
