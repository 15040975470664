import React, { Component } from "react";
import PropTypes from "prop-types";

import Textarea from "react-textarea-autosize";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";

const fieldTypes = {
  text: {
    defaultName: "Name",
    defaultHelp: "Your full name",
    description: "Text",
  },
  textarea: {
    defaultName: "Description",
    defaultHelp: "A summary of the problem",
    description: "Long Text",
  },
  email: {
    defaultName: "Email",
    defaultHelp: "Your email address",
    description: "Email Address",
  },
  tel: {
    defaultName: "Phone",
    defaultHelp: "Your phone number",
    description: "Phone Number",
  },
  url: {
    defaultName: "Website",
    defaultHelp: "Your website URL",
    description: "URL",
  },
  number: {
    defaultName: "Age",
    defaultHelp: "Your age",
    description: "Number",
  },
  checkbox: {
    defaultName: "Agree",
    defaultHelp: "Do you agree to the terms?",
    description: "Checkbox",
  },
  date: {
    defaultName: "Birthday",
    defaultHelp: "Your birthday",
    description: "Date",
  },
  datetime: {
    defaultName: "Appointment Time",
    defaultHelp: "When you would like your appointment",
    description: "Date with Time",
  },
};

const defaultField = () => {
  const types = Object.keys(fieldTypes);
  // const type = types[Math.floor(Math.random() * types.length)];
  const type = types[0];
  const field = fieldTypes[type];
  return {
    name: field.defaultName,
    type,
    help: "", //field.defaultHelp,
    required: false,
  };
};

const DragHandle = SortableHandle(() => (
  <i className="fa fa-bars" style={{ cursor: "row-resize", color: "#888" }} />
));

const Field = ({ onChange, onDelete, field }) => {
  const { name, type, help, required, sendUploaderNotifications } = field;

  return (
    <tr>
      <td style={{ verticalAlign: "middle" }}>
        <DragHandle />
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <select
          className="page_questions_type form-control"
          onChange={e => {
            const newField = { ...field, type: e.target.value };
            if (newField.type === "email")
              newField.sendUploaderNotifications = true;
            onChange(newField);
          }}
          value={type}
        >
          {Object.entries(fieldTypes).map(([value, { description }]) => (
            <option key={value} value={value}>
              {description}
            </option>
          ))}
        </select>
      </td>
      <td
        className={name.length === 0 ? "has-error" : ""}
        style={{ verticalAlign: "middle" }}
      >
        <input
          type="text"
          required={true}
          className="page_questions_name form-control"
          value={name}
          onChange={e => onChange({ ...field, name: e.target.value })}
          placeholder={"ex: " + fieldTypes[type].defaultName}
        />
        {name.length === 0 && (
          <span id="helpBlock" className="help-block">
            cannot be blank
          </span>
        )}
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <Textarea
          type="text"
          className="page_questions_help form-control"
          value={help}
          onChange={e => onChange({ ...field, help: e.target.value })}
          placeholder={"ex: " + fieldTypes[type].defaultHelp}
        />
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={required}
              onChange={e => onChange({ ...field, required: e.target.checked })}
            />{" "}
            Required
          </label>

          {false && type === "email" && (
            <label>
              <input
                type="checkbox"
                checked={sendUploaderNotifications}
                onChange={e =>
                  onChange({
                    ...field,
                    sendUploaderNotifications: e.target.checked,
                  })
                }
              />{" "}
              Send upload confirmation emails
            </label>
          )}
        </div>
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <button
          className="btn btn-danger"
          role="button"
          title="delete question"
          onClick={e => {
            e.preventDefault();
            onDelete();
          }}
        >
          <i className="fa fa-trash" />
        </button>
      </td>
    </tr>
  );
};

const SortableField = SortableElement(Field);
const SortableFields = SortableContainer(({ fields, onChange }) => {
  return (
    <tbody>
      {fields.map((field, i) => (
        <SortableField
          key={`field-${i}`}
          index={i}
          field={field}
          onChange={field =>
            onChange(Object.assign([], fields, { [i]: field }))
          }
          onDelete={field =>
            onChange(fields.filter((field, index) => index !== i))
          }
        />
      ))}
    </tbody>
  );
});

export class FormBuilder extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onChange, form } = this.props;

    onChange(arrayMove(form, oldIndex, newIndex));
  };

  render() {
    const { onChange, form, maxQuestions } = this.props;

    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th />
              <th>Type</th>
              <th>Prompt</th>
              <th>Description</th>
              <th />
              <th />
            </tr>
          </thead>
          <SortableFields
            fields={form}
            onSortEnd={this.onSortEnd}
            useDragHandle={true}
            onChange={onChange}
            lockAxis={"y"}
          />
        </table>
        <button
          className="btn btn-link btn-block"
          onClick={e => {
            e.preventDefault();
            onChange([...form, defaultField()]);
          }}
          disabled={form.length >= maxQuestions}
        >
          <i className="fa fa-plus" />
          Add a question
        </button>
        {form.length >= maxQuestions && (
          <p className="help-block">
            <a href="/account/billing">Upgrade</a> to add more questions for
            your uploaders.
          </p>
        )}
      </div>
    );
  }
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
FormBuilder.propTypes = { form: PropTypes.array, onChange: PropTypes.func };

export default FormBuilder;
