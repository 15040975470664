import { useState, useEffect } from "react";
import axios from "axios";

import useDebounce from "./useDebounce";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isEmailValid = email => {
  return emailRegex.test(email);
};

export default defaultEmail => {
  const [email, setEmail] = useState(defaultEmail);
  const [emailLoading, setLoading] = useState(true);
  const [emailAvailable, setAvailable] = useState(null);
  const [emailMessage, setMessage] = useState("");
  const [emailValid, setValid] = useState(false);

  // overwrite state with props
  useEffect(() => setEmail(defaultEmail), [defaultEmail]);

  // anytime the email changes, we need to reload
  useEffect(() => {
    setLoading(true);
    setAvailable(null);
  }, [email]);

  useEffect(() => {
    setValid(isEmailValid(email) && emailAvailable && !emailLoading);

    if (email.length <= 4) {
      setMessage("");
    } else if (!isEmailValid(email)) {
      setMessage("🔬 Hmm, that doesn't look like an email address yet...");
    } else if (emailLoading) {
      setMessage(`🧐 checking ${email}...`);
    } else if (emailAvailable) {
      setMessage(`✅ looks good!`);
    } else {
      setMessage(`❌ Ahh drat, there's already an account for ${email}.`);
    }
  }, [email, emailLoading, emailAvailable]);

  useDebounce(
    () => {
      if (!isEmailValid(email)) return;

      axios
        .get(`/existance/email?email=${encodeURIComponent(email)}`)
        .then(({ data: { available, email: checkedEmail } }) => {
          if (email === checkedEmail) {
            setLoading(false);
            setAvailable(available);
          }
        })
        .catch(err => {
          console.log({ err });
        });
    },
    1000,
    [email]
  );

  return {
    email,
    setEmail,
    emailLoading,
    emailAvailable,
    emailValid,
    emailMessage,
  };
};
