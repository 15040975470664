import React, { Component } from "react";
import PropTypes from "prop-types";

export class SecurityPanel extends Component {
  render() {
    const { page, user, onChange } = this.props;

    const { active_plan } = user;
    const canEditAccessCode = !!active_plan.access_code;

    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="page_access_code">
            Access code
          </label>
          <input
            className="form-control"
            type="text"
            // value=""
            // name="page[access_code]"
            id="page_access_code"
            value={page.access_code || ""}
            onChange={e => onChange({ access_code: e.target.value })}
            disabled={!canEditAccessCode}
          />
          {canEditAccessCode && (
            <span className="help-block">
              protect this form from anonymous uploaders
            </span>
          )}
          {canEditAccessCode || (
            <span className="help-block">
              <a href="/account/billing">Upgrade</a> to protect this form from
              anonymous uploaders with an access code
            </span>
          )}
        </div>
      </div>
    );
  }
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
SecurityPanel.propTypes = {
  page: PropTypes.object,
  onChange: PropTypes.func,
};

export default SecurityPanel;
