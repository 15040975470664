import React from "react";

export default ({ next, prev, nextDisabled, nextText }) => (
  <div style={{ display: "flex" }}>
    {prev && (
      <button type="button" className="btn btn-default btn-lg" onClick={prev}>
        Back
      </button>
    )}
    <button
      disabled={nextDisabled}
      type="submit"
      className="btn btn-primary btn-lg"
      style={{ flex: 1 }}
    >
      {nextText || "Next"}
    </button>
  </div>
);
