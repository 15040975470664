import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  HashRouter,
  Route,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";

import axios from "axios";

import slugify from "slugify";

import HideHeaderFooter from "./HideHeaderFooter";
import NextPrevButtons from "./onboarding/NextPrevButtons";
import ChooseSlug from "./onboarding/ChooseSlug";
import SavePage from "./onboarding/SavePage";
import EmailPage from "./onboarding/EmailPage";

const HiFromChristian = () => (
  <p style={{ fontSize: "12pt", color: "#666" }}>
    <img
      src="https://i.imgur.com/FuHznQC.jpg"
      alt=""
      style={{
        float: "right",
        borderRadius: "100%",
        width: 100,
        marginLeft: 20,
      }}
    />
    Hi! I'm Christian, the founder of Fileinbox. If you get stuck at any point,
    or would like some help, click that shiny blue "<b>Help & Support</b>"
    button in the lower right corner of the screen to send me a message. I'm
    happy to help you :)
  </p>
);

const Progress = ({ percent = 0, label = null }) => (
  <div className="progress">
    <div
      className="progress-bar progress-bar-success progress-bar-striped active"
      role="progressbar"
      aria-valuenow={percent}
      aria-valuemin="0"
      aria-valuemax="100"
      style={{ width: `${percent}%` }}
    >
      {" " || label || `${percent}% done`}
    </div>
  </div>
);

// const Page0 = ({ next }) => (
//   <Fragment>
//     <div className="well">
//       <p>Ready to make a file upload page? Let's do it!</p>

//       <p>
//         In a few quick steps, you'll have a beautiful webpage you can send to
//         people to start receiving large files.
//       </p>

//       <p>After we get the basics set up, you can do fancier stuff like:</p>
//       <ul>
//         <li>
//           <b>embedding</b> this upload page in your own website
//         </li>
//         <li>
//           sending uploaded files to your <b>cloud storage</b>
//         </li>
//         <li>
//           <b>automatically sorting</b> files based on who uploaded them
//         </li>
//         <li>
//           <b>receiving files as big as 1GB</b>
//         </li>
//       </ul>
//       <button className="btn btn-primary btn-lg btn-block" onClick={next}>
//         Let's make a file upload page!
//       </button>
//     </div>
//     <HiFromChristian />
//   </Fragment>
// );

// const Page1 = ({ value, onChange, next, prev }) => (
//   <div className="well">
//     <form
//       onSubmit={e => {
//         e.preventDefault();
//         next();
//       }}
//     >
//       <div className="form-group">
//         <label htmlFor="name">
//           First, what's the name of the Business or Project that needs to
//           receive files?
//         </label>
//         <input
//           id="name"
//           type="text"
//           className="form-control input-lg"
//           placeholder={`ex: "Acme Accounting"`}
//           value={value}
//           onChange={onChange}
//         />
//         <span className="help-block">
//           This helps Fileinbox figure out the web address and default title of
//           your upload page.
//         </span>
//       </div>

//       <NextPrevButtons
//         next={next}
//         prev={prev}
//         nextDisabled={!(value && value.length > 2)}
//       />
//     </form>
//   </div>
// );

class CreatePage extends Component {
  state = {
    name: "",
    slug: "",
    email: "",
    password: "",
    humanName: "",
    errorMessage: null,
    submitting: false,
  };

  componentDidCatch(error, info) {
    Honeybadger.notify(error);
    this.setState({ errorMessage: error.message });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { name, slug, email, password, humanName } = this.state;
    localStorage.setItem(
      "fi:CreatePage",
      JSON.stringify({ name, slug, email, password, humanName })
    );
  };

  componentDidMount = () => {
    const cache = localStorage.getItem("fi:CreatePage");
    if (cache) {
      try {
        this.setState(JSON.parse(cache));
      } catch (e) {
        console.error("Error deserializing localStorage cache.");
      }
    }
  };

  pageNumber = () => {
    const pathname = this.props.history.location.pathname;
    return parseInt(pathname.replace(/[^0-9]/g, "")) || 0;
  };

  prevPagePath = () => `${this.pageNumber() - 1}`;
  nextPagePath = () => `${this.pageNumber() + 1}`;

  visitPrevPage = () => {
    this.props.history.push(this.prevPagePath());
  };

  visitNextPage = () => {
    this.props.history.push(this.nextPagePath());
  };

  renderProgress = () => {
    const totalSteps = 2;
    const currentStep = this.pageNumber();
    const percent = currentStep / totalSteps;
    return (
      <Progress
        percent={percent * 100}
        label={`${currentStep}/${totalSteps} steps done`}
      />
    );
  };

  submit = () => {
    this.setState({ submitting: true });
    const { name, slug, email, password, humanName } = this.state;

    const { authenticity_token } = this.props;

    axios
      .post(`/users/create_with_page.json`, {
        user: { name: humanName, email, password },
        page: { slug, title: `Send files to ${name}` },
        authenticity_token,
      })
      .then(res => {
        // console.dir(res.data);
        const pageUrl = `/${res.data.page.slug}?onboarding=true`;
        localStorage.setItem("fi:CreatePage", "{}");
        window.location.href = pageUrl;
        // this.setState({ submitting: false });
      })
      .catch(err => {
        console.log({ err });
        let errorMessage = err.message;
        if (err.response && err.response.data) {
          errorMessage = JSON.stringify(err.response.data);
        }
        this.setState({ errorMessage, submitting: false });
      });
  };

  render() {
    const {
      name,
      slug,
      email,
      password,
      humanName,
      errorMessage,
      submitting,
    } = this.state;

    // if (errorMessage)
    //   return (
    //     <div className="alert alert-danger" role="alert">
    //       <i className="fa fa-exclamation-triangle" />{" "}
    //       <span className="sr-only">Error:</span>
    //       {errorMessage}
    //     </div>
    //   );

    return (
      <div style={{ fontSize: "15pt", minHeight: "90vh" }}>
        <HideHeaderFooter />
        <h1>Create {name ? `${name}'s` : "a"} File Upload Page</h1>
        {this.renderProgress()}
        <Route path="/" exact render={() => <Redirect to="/0" />} />
        {/* <Route path="/0" render={() => <Page0 next={this.visitNextPage} />} /> */}
        {/*<Route
          path="/0"
          render={() => (
            <Page1
              value={name}
              onChange={e => this.setState({ name: e.target.value })}
              next={this.visitNextPage}
              prev={this.visitPrevPage}
            />
          )}
        />*/}
        <Route
          path="/0"
          render={() => (
            <ChooseSlug
              name={name}
              setName={name => this.setState({ name })}
              value={slug || slugify(name)}
              setValue={slug => this.setState({ slug })}
              next={this.visitNextPage}
            />
          )}
        />
        <Route
          path="/1"
          render={() => (
            <EmailPage
              password={password}
              setPassword={password => this.setState({ password })}
              name={humanName}
              setName={humanName => this.setState({ humanName })}
              value={email}
              setValue={email => this.setState({ email })}
              submitting={submitting}
              next={this.submit}
              prev={this.visitPrevPage}
            />
          )}
        />
        {errorMessage ? (
          <div
            className="alert alert-danger"
            role="alert"
            style={{ marginBottom: 20 }}
          >
            <i className="fa fa-exclamation-triangle" />{" "}
            <span className="sr-only">Error:</span>
            {errorMessage}
          </div>
        ) : null}

        <HiFromChristian />
        {/*<Route
          path="/4"
          render={() => (
            <SavePage
              page={{ title: `Upload files to ${name}`, slug }}
              // next={this.visitNextPage}
              // prev={this.visitPrevPage}
            />
          )}
        />*/}
      </div>
    );
  }
}

const CreatePageWithRouter = withRouter(CreatePage);

export default props => (
  <HashRouter>
    <CreatePageWithRouter {...props} />
  </HashRouter>
);
