import React, { useState, useEffect } from "react";

import NextPrevButtons from "./NextPrevButtons";
import useEmail from "../hooks/useEmail";

export default ({
  value,
  setValue,
  password,
  setPassword,
  name,
  setName,
  next,
  prev,
  submitting,
}) => {
  const {
    email,
    setEmail,
    emailLoading,
    emailAvailable,
    emailMessage,
    emailValid,
  } = useEmail(value);

  useEffect(() => setEmail(value), [value]);

  let passwordValid = false;
  let passwordMessage = null;
  if (!password) {
  } else if (password.length < 8) {
    passwordValid = false;
    passwordMessage = "📏 At least 8 characters please";
  } else {
    passwordValid = true;
    passwordMessage = "✅ Looks good!";
  }

  return (
    <div className="well">
      <form
        onSubmit={e => {
          e.preventDefault();
          if (emailValid) {
            setValue(email);
            next();
          }
        }}
      >
        <fieldset disabled={submitting}>
          <div className="form-group">
            <label htmlFor="userEmail">
              Great - what email address would you like uploaded files to get
              sent to?
            </label>

            <input
              id="userEmail"
              type="email"
              className="form-control input-lg"
              placeholder={`Your email address`}
              value={value}
              onChange={e => setValue(e.target.value.replace(/\s+/g, ""))}
            />

            {emailMessage && <span className="help-block">{emailMessage}</span>}
          </div>

          <p>You can link Dropbox or Google Drive after we get set up.</p>

          <div className="form-group" style={{ marginTop: 20 }}>
            <label htmlFor="password">
              What's a good password we can use so that no one else can get your
              files?
            </label>

            <input
              id="password"
              type="password"
              className="form-control input-lg"
              placeholder={`A pass phrase that's easy to remember`}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />

            {passwordMessage && (
              <span className="help-block">{passwordMessage}</span>
            )}
          </div>

          <div className="form-group" style={{ marginTop: 20 }}>
            <label htmlFor="name">Also, hi! What's your name?</label>

            <input
              id="name"
              type="name"
              className="form-control input-lg"
              placeholder={`Your name`}
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>

          <NextPrevButtons
            next={next}
            prev={prev}
            nextText={!emailValid ? "Create Upload Page" : "Create Upload Page"}
            nextDisabled={!emailValid || !passwordValid}
          />
        </fieldset>
      </form>
    </div>
  );
};
