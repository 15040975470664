import React, { useState, useEffect } from "react";
import axios from "axios";

export default ({ page }) => {
  useEffect(() => {
    console.log("creating...");
  }, [page]);

  return <div>Creating your upload page...</div>;
};
