import React from "react";
import PropTypes from "prop-types";

export const FaIcon = ({ name }) => <i className={`fa fa-${name}`} />;

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
FaIcon.propTypes = {
  name: PropTypes.string,
};

export default FaIcon;
